exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-components-about-cards-tsx": () => import("./../../../src/pages/about/_components/AboutCards.tsx" /* webpackChunkName: "component---src-pages-about-components-about-cards-tsx" */),
  "component---src-pages-about-components-about-company-founder-tsx": () => import("./../../../src/pages/about/_components/AboutCompanyFounder.tsx" /* webpackChunkName: "component---src-pages-about-components-about-company-founder-tsx" */),
  "component---src-pages-about-components-aboutus-component-tsx": () => import("./../../../src/pages/about/_components/AboutusComponent.tsx" /* webpackChunkName: "component---src-pages-about-components-aboutus-component-tsx" */),
  "component---src-pages-about-components-hero-tsx": () => import("./../../../src/pages/about/_components/Hero.tsx" /* webpackChunkName: "component---src-pages-about-components-hero-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-careers-components-form-component-tsx": () => import("./../../../src/pages/careers/_components/FormComponent.tsx" /* webpackChunkName: "component---src-pages-careers-components-form-component-tsx" */),
  "component---src-pages-careers-components-hero-tsx": () => import("./../../../src/pages/careers/_components/Hero.tsx" /* webpackChunkName: "component---src-pages-careers-components-hero-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-components-footer-tsx": () => import("./../../../src/pages/_components/Footer.tsx" /* webpackChunkName: "component---src-pages-components-footer-tsx" */),
  "component---src-pages-components-form-button-tsx": () => import("./../../../src/pages/_components/Form/Button.tsx" /* webpackChunkName: "component---src-pages-components-form-button-tsx" */),
  "component---src-pages-components-form-check-box-tsx": () => import("./../../../src/pages/_components/Form/CheckBox.tsx" /* webpackChunkName: "component---src-pages-components-form-check-box-tsx" */),
  "component---src-pages-components-form-date-picker-tsx": () => import("./../../../src/pages/_components/Form/DatePicker.tsx" /* webpackChunkName: "component---src-pages-components-form-date-picker-tsx" */),
  "component---src-pages-components-form-input-tsx": () => import("./../../../src/pages/_components/Form/Input.tsx" /* webpackChunkName: "component---src-pages-components-form-input-tsx" */),
  "component---src-pages-components-form-select-tsx": () => import("./../../../src/pages/_components/Form/Select.tsx" /* webpackChunkName: "component---src-pages-components-form-select-tsx" */),
  "component---src-pages-components-form-text-area-tsx": () => import("./../../../src/pages/_components/Form/TextArea.tsx" /* webpackChunkName: "component---src-pages-components-form-text-area-tsx" */),
  "component---src-pages-components-header-tsx": () => import("./../../../src/pages/_components/Header.tsx" /* webpackChunkName: "component---src-pages-components-header-tsx" */),
  "component---src-pages-components-hero-component-tsx": () => import("./../../../src/pages/_components/HeroComponent.tsx" /* webpackChunkName: "component---src-pages-components-hero-component-tsx" */),
  "component---src-pages-components-info-cards-tsx": () => import("./../../../src/pages/_components/InfoCards.tsx" /* webpackChunkName: "component---src-pages-components-info-cards-tsx" */),
  "component---src-pages-components-main-wrapper-tsx": () => import("./../../../src/pages/_components/MainWrapper.tsx" /* webpackChunkName: "component---src-pages-components-main-wrapper-tsx" */),
  "component---src-pages-components-section-title-tsx": () => import("./../../../src/pages/_components/SectionTitle.tsx" /* webpackChunkName: "component---src-pages-components-section-title-tsx" */),
  "component---src-pages-components-section-wrapper-tsx": () => import("./../../../src/pages/_components/SectionWrapper.tsx" /* webpackChunkName: "component---src-pages-components-section-wrapper-tsx" */),
  "component---src-pages-contact-components-form-component-tsx": () => import("./../../../src/pages/contact/_components/FormComponent.tsx" /* webpackChunkName: "component---src-pages-contact-components-form-component-tsx" */),
  "component---src-pages-contact-components-hero-tsx": () => import("./../../../src/pages/contact/_components/Hero.tsx" /* webpackChunkName: "component---src-pages-contact-components-hero-tsx" */),
  "component---src-pages-contact-components-iframe-component-tsx": () => import("./../../../src/pages/contact/_components/IframeComponent.tsx" /* webpackChunkName: "component---src-pages-contact-components-iframe-component-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-digital-component-digital-marketing-tsx": () => import("./../../../src/pages/digital/_component/DigitalMarketing.tsx" /* webpackChunkName: "component---src-pages-digital-component-digital-marketing-tsx" */),
  "component---src-pages-digital-component-hero-tsx": () => import("./../../../src/pages/digital/_component/Hero.tsx" /* webpackChunkName: "component---src-pages-digital-component-hero-tsx" */),
  "component---src-pages-digital-development-component-hero-tsx": () => import("./../../../src/pages/digitalDevelopment/_component/Hero.tsx" /* webpackChunkName: "component---src-pages-digital-development-component-hero-tsx" */),
  "component---src-pages-digital-development-component-soft-web-development-tsx": () => import("./../../../src/pages/digitalDevelopment/_component/SoftWebDevelopment.tsx" /* webpackChunkName: "component---src-pages-digital-development-component-soft-web-development-tsx" */),
  "component---src-pages-digital-development-index-tsx": () => import("./../../../src/pages/digitalDevelopment/index.tsx" /* webpackChunkName: "component---src-pages-digital-development-index-tsx" */),
  "component---src-pages-digital-index-tsx": () => import("./../../../src/pages/digital/index.tsx" /* webpackChunkName: "component---src-pages-digital-index-tsx" */),
  "component---src-pages-hightech-component-hero-tsx": () => import("./../../../src/pages/hightech/_component/Hero.tsx" /* webpackChunkName: "component---src-pages-hightech-component-hero-tsx" */),
  "component---src-pages-hightech-component-high-tech-solution-tsx": () => import("./../../../src/pages/hightech/_component/HighTechSolution.tsx" /* webpackChunkName: "component---src-pages-hightech-component-high-tech-solution-tsx" */),
  "component---src-pages-hightech-index-tsx": () => import("./../../../src/pages/hightech/index.tsx" /* webpackChunkName: "component---src-pages-hightech-index-tsx" */),
  "component---src-pages-home-components-device-mobile-application-tsx": () => import("./../../../src/pages/_home/_components/DeviceMobileApplication.tsx" /* webpackChunkName: "component---src-pages-home-components-device-mobile-application-tsx" */),
  "component---src-pages-home-components-hero-tsx": () => import("./../../../src/pages/_home/_components/Hero.tsx" /* webpackChunkName: "component---src-pages-home-components-hero-tsx" */),
  "component---src-pages-home-components-testimonial-component-tsx": () => import("./../../../src/pages/_home/_components/TestimonialComponent.tsx" /* webpackChunkName: "component---src-pages-home-components-testimonial-component-tsx" */),
  "component---src-pages-home-components-what-we-do-component-tsx": () => import("./../../../src/pages/_home/_components/WhatWeDoComponent.tsx" /* webpackChunkName: "component---src-pages-home-components-what-we-do-component-tsx" */),
  "component---src-pages-home-components-why-us-component-tsx": () => import("./../../../src/pages/_home/_components/WhyUsComponent.tsx" /* webpackChunkName: "component---src-pages-home-components-why-us-component-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/_home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ui-ux-design-component-hero-tsx": () => import("./../../../src/pages/ui-ux-Design/_component/Hero.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-component-hero-tsx" */),
  "component---src-pages-ui-ux-design-component-ui-ux-design-tsx": () => import("./../../../src/pages/ui-ux-Design/_component/UI-UX-Design.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-component-ui-ux-design-tsx" */),
  "component---src-pages-ui-ux-design-index-tsx": () => import("./../../../src/pages/ui-ux-Design/index.tsx" /* webpackChunkName: "component---src-pages-ui-ux-design-index-tsx" */),
  "component---src-pages-what-we-do-component-device-management-tsx": () => import("./../../../src/pages/what-we-do/component/DeviceManagement.tsx" /* webpackChunkName: "component---src-pages-what-we-do-component-device-management-tsx" */),
  "component---src-pages-what-we-do-component-hero-tsx": () => import("./../../../src/pages/what-we-do/component/Hero.tsx" /* webpackChunkName: "component---src-pages-what-we-do-component-hero-tsx" */),
  "component---src-pages-what-we-do-component-home-device-service-tsx": () => import("./../../../src/pages/what-we-do/component/HomeDeviceService.tsx" /* webpackChunkName: "component---src-pages-what-we-do-component-home-device-service-tsx" */),
  "component---src-pages-what-we-do-component-home-mobile-service-tsx": () => import("./../../../src/pages/what-we-do/component/HomeMobileService.tsx" /* webpackChunkName: "component---src-pages-what-we-do-component-home-mobile-service-tsx" */),
  "component---src-pages-what-we-do-component-mobile-application-tsx": () => import("./../../../src/pages/what-we-do/component/MobileApplication.tsx" /* webpackChunkName: "component---src-pages-what-we-do-component-mobile-application-tsx" */),
  "component---src-pages-what-we-do-index-tsx": () => import("./../../../src/pages/what-we-do/index.tsx" /* webpackChunkName: "component---src-pages-what-we-do-index-tsx" */)
}

